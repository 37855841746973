import React from 'react'
import { indexOf, without } from 'lodash';

const toggleArray = (collection, item) => {
  const index = indexOf(collection, item);
  if (index !== -1) {
    return without(collection, item);
  }
  return [...collection, item];
}
const csrfToken = document.querySelector('[name=csrf-token]').content

export default class ManualPayment extends React.Component {
  constructor(props){
    super(props)
    this.state = { user: { first_name: '', last_name: '', email: '' }, course_ids: [], btnText: "Create" }
  }

  async createUser(e){
    e.preventDefault()
    this.setState({btnText: "Creating User..."})

    let userRespons = await fetch(`/admin/users/create_manually.json`, { method: "POST", body: JSON.stringify({ ...this.state.user }), headers: { 'Content-Type': 'application/json', 'X-CSRF-TOKEN': csrfToken }}).then((r)=> r.text())

    if(isNaN(userRespons)) {
      this.setState({btnText: "Try Again"})
      alert(userRespons)
    }else this.redirectToStripe.bind(this, userRespons)()
  }

  courseChecked(courseId){
    let new_course_ids = toggleArray(this.state.course_ids, courseId)
    this.setState({course_ids: new_course_ids})
  }

  async redirectToStripe(userId){
    if(this.state.course_ids.length == 0){
      this.setState({btnText: "User Created"})
      return;
    }

    this.setState({btnText: "Processing payment..."})
    let stripe = Stripe(this.props.publishable_key)
    let sessionId = await fetch(`/admin/users/courses_session_generator.json`, { method: "POST", body: JSON.stringify({ user_id: userId, course_ids: this.state.course_ids }), headers: { 'Content-Type': 'application/json', 'X-CSRF-TOKEN': csrfToken }}).then((r)=> r.text())

    stripe.redirectToCheckout({ sessionId: sessionId }).then((result) => { alert(result.error.message) })
  }

  render() {
    return (
      <form
        id="new_usert"
        action=""
        acceptCharset="UTF-8"
        method=""
        onSubmit={this.createUser.bind(this)}
      >
        <div className="color-white"></div>
        <div className="form-fullname">
          <input
            type="text"
            className="first-name"
            autoComplete="First name"
            placeholder="First Name"
            required="required"
            value={this.state.user.first_name} onChange={(e)=>this.setState({user: {...this.state.user, first_name: e.target.value}})}
          />
          <input
            type="text"
            name="user[last_name]"
            id="user_last_name"
            className="last-name"
            autoComplete="Last name"
            placeholder="Last Name"
            required="required"
            value={this.state.user.last_name} onChange={(e)=>this.setState({user: {...this.state.user, last_name: e.target.value}})}
          />
        </div>
        <div className="form-email">
          <input
            type="email"
            name="user[email]"
            id="user_email"
            placeholder="Email"
            required="required"
            value={this.state.user.email} onChange={(e)=>this.setState({user: {...this.state.user, email: e.target.value}})}
          />
        </div>
        <br/>
        <div className="form-fullname"></div>
        <div className="form-email color-white">
          <label>Courses: </label>
        </div>
        {this.props.courses.map((course)=>
          <div key={`${course.id}-course-checkobx`} className="form-item form-checkbox checkbox-style color-white">
            <input type="checkbox" id={course.id} defaultValue={course.id} onChange={this.courseChecked.bind(this, course.id)}/>
            <label htmlFor={course.id}>
              <i className="icon-checkbox icon md-check-1" />
              {course.name}
            </label>
          </div>
        )}
        <div className="form-submit-1">
          <input
          type="submit"
          name="commit"
          value={this.state.btnText}
          className="mc-btn btn-style-1"
          />
        </div>
      </form>
    )
  }
}
