import React from 'react'

export default class Score extends React.Component {

  constructor(props){
    super(props)
    this.state = { score: this.props.score }
  }

  handleClick(value, e) {
    e.preventDefault()
    if (this.props.disabled) return;
    this.setState({score: value})
  }

  render() {
    const { score } = this.state
    const array = [...Array(5).keys()].map(x => x + 1)

    return (
      <div className="rating">
        { (array).map( (value) =>
          <div key={"star-"+value} style={{ display: "inline" }}>
            <a href="#" className={value <= score ? 'active' : ''} onClick={ this.handleClick.bind(this, value) }></a>
          </div>
        )}
        <input type="hidden" name="score" value={this.state.score}/>
      </div>
    )
  }
}
