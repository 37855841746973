import React from 'react'

export default class Checkout extends React.Component {
  constructor(props){
    super(props)
  }

  async handleClick(e){
    e.preventDefault()

    e.target.value = "Processing..."
    e.target.disabled = true

    var endpoint = this.props.type == 'course' ? `/courses/${this.props.course_id}/stripe_session_generator` : '/user/subscriptions/stripe_session_generator'
    var stripe = Stripe(this.props.publishable_key);
    var sessionId = await fetch(endpoint).then((r)=> r.text());

    stripe.redirectToCheckout({ sessionId: sessionId }).then((result) => { alert(result.error.message) });

  }

  render() {

    return (
      <div>
        { this.props.user_id ?
          <input type="button" onClick={ this.handleClick.bind(this) } value={this.props.btn_text} className="mc-btn btn-style-1" data-disable-with={this.props.btn_text}/>
          :
          <a className="mc-btn btn-style-1" data-method="get" href="/user/login">Login to Buy Course</a>
        }
      </div>
    )
  }
}
