import React from 'react'

export default class Checkout extends React.Component {
  constructor(props){
    super(props)
  }

  componentDidMount(){

    const { course } = this.props
    const csrfToken = document.querySelector('[name=csrf-token]').content

    paypal.Buttons({
      style: {
        label: 'checkout',
        size: 'large',
        tagline: false,
        height: 48
      },
      createOrder: async (data, actions)=>{
        return await fetch(`/courses/${course.id}/paypal_order_generator.json`).then((r)=> r.text())
      },
      onApprove: (data, actions)=> {

        return actions.order.capture().then(async(details)=> {
          location.replace(`/courses/${course.id}/success`)
        })
      }
    }).render('#paypal-button-container');


  }

  render() {

    return (
      <div>
        { this.props.user_id ?
          <div className="paypal-course-price" id="paypal-button-container"></div> : null
        }
      </div>
    )
  }
}
