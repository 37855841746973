import React from 'react'
import Slider from 'react-input-slider'

export default class ThumbnailPicker extends React.Component {
  constructor(props) {
    super(props)

    const { thumbnail_start_at, wistia_duration } = this.props

    this.state = {
      thumbnail_start_at
    }
  }

  render() {
    const { wistia_original_url, wistia_duration, name } = this.props
    const { thumbnail_start_at } = this.state

    return (
      <div>
        <div className="upload-thumb">
            <img src={ `${wistia_original_url}?video_still_time=${thumbnail_start_at}&image_crop_resized=320x180` } />
            <input type="hidden" name={name} value={thumbnail_start_at} />
        </div>
        <Slider
          axis="x"
          xstep={1}
          xmin={1}
          style={{width: '320px'}}
          xmax={wistia_duration}
          x={thumbnail_start_at}
          onChange={({ x }) => this.setState({ thumbnail_start_at: x })}
        />
      </div>

    )
  }
}
