import React from 'react'

export default class Stars extends React.Component {

  constructor(props){
    super(props)
  }

  handleClick(e) {
    e.preventDefault()
  }

  render() {
    const starts = 5
    const { score } = this.props

    const array =  [...Array(starts).keys()].map(x => x + 1)

    return (
      <div className="rating">
        {array.map((value) => {
          return <a key={"star-"+value} href="#" className={value <= score ? 'active' : ''} onClick={ this.handleClick.bind(this) }></a>
        })}
      </div>
    )
  }
}
