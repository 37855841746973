/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import WebpackerReact from 'webpacker-react'
import axios          from 'axios'
import Demo           from 'components/demo'
import Stars          from 'components/stars'
import Score          from 'components/score'
import WistiaUploader from 'components/wistia-uploader'
import WistiaPlayer   from 'components/wistia-player'
import QuestionForm   from 'components/question-form'
import TagsInput      from 'components/tags-input'
import ThumbnailPicker from 'components/thumbnail-picker'
import StripeCheckout from 'components/stripe/checkout'
import PaypalCheckout from 'components/paypal/checkout'
import PaypalSubscription from 'components/paypal/subscription'
import StripeManualPayment from 'components/stripe/manual_payment'


WebpackerReact.setup(
  {
    Demo,
    Stars,
    Score,
    WistiaUploader,
    WistiaPlayer,
    QuestionForm,
    TagsInput,
    ThumbnailPicker,
    StripeCheckout,
    PaypalCheckout,
    PaypalSubscription,
    StripeManualPayment,
  }
)

// ADD CSRF TOKEN
const csrfToken = document.querySelector("meta[name=csrf-token]").content
axios.defaults.headers.common['X-CSRF-Token'] = csrfToken
axios.defaults.headers.common['Accept'] = 'application/json'
