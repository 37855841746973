import React from 'react'
import axios from 'axios'

export default class QuestionForm extends React.Component {
  constructor(props){
    super(props)
    const { course: { id: course_id }, section: { id: section_id }, lesson: { id: lesson_id }, question: { id, name, options, question_type } } = this.props

    this.state = {
      course_id,
      section_id,
      lesson_id,
      id,
      name,
      options,
      question_type,
      files: []
    }
  }

  handleNameChange(event) {
    this.setState({ name: event.target.value })
  }

  handleQuestionTypeChange(event) {
    this.setState({ question_type: event.target.value })
  }

  onQuestionTitleChange = (e, index) => {
    const { options } = this.state

    const newOptions = [...options]
    newOptions[index] = {...options[index], title: e.target.value}

    this.setState({ options: newOptions })
  }

  onQuestionCorrectChange = (e, index) => {
    const { options } = this.state

    const newOptions = [...options]
    newOptions[index] = {...options[index], correct: e.target.value}

    this.setState({ options: newOptions })
  }

  onQuestionFileAdd = (e) =>{
    // let formData = new FormData()
    // _.forEach(e.target.files, (file => formData.append('files', file ) ))

    // console.log(formData)

    this.setState({ files: e.target.files })
    // this.setState({ files: e.target.files })
  }

  updateQuestion = () => {
    const formData = new FormData()

    const { course_id, section_id, lesson_id, id, name, options, question_type, files } = this.state
    const url = `/admin/courses/${course_id}/sections/${section_id}/lessons/${lesson_id}/questions/${id}.json`

    formData.append("question[name]", name)
    formData.append("question[options]", JSON.stringify(options))
    formData.append("question[question_type]", question_type)
    _.forEach(files, (file => formData.append('question[files][]', file ) ))


    axios({
      method: 'PUT',
      url: url,
      data: formData,
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
    .then((response) => {
      console.log('RESPONSE', response)
      Turbolinks.visit(location.toString())
    })
    .catch((error) => {
      console.log('ERROR', error)
    })
  }

  createQuestion = () => {
    const formData = new FormData()

    const { course_id, section_id, lesson_id, name, options, question_type, files } = this.state
    const url = `/admin/courses/${course_id}/sections/${section_id}/lessons/${lesson_id}/questions`

    formData.append("question[name]", name)
    formData.append("question[options]", JSON.stringify(options))
    formData.append("question[question_type]", question_type)
    _.forEach(files, (file => formData.append('question[files][]', file ) ))

    axios({
      method: 'POST',
      url: url,
      data: formData,
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
    .then((response) => {
      console.log('RESPONSE', response)
      Turbolinks.visit(location.toString())
    })
    .catch((error) => {
      console.log('ERROR', error)
    })

  }

  render() {
    const { id, name, question_type, options } = this.state

    const OPTIONS = ["A", "B", "C", "D", "E", "F", "G"]

    return (
      <div className="form-horizontal">
        <div className="form-group">
          <textarea
            rows="3"
            placeholder="Question"
            required={true}
            className="form-control"
            name="question[name]"
            id="question_name"
            value={name}

            onChange={this.handleNameChange.bind(this)}>
          </textarea>
        </div>

        <div className="form-group">
          <div className="form-question mc-select">
            <select className="select" name="question[question_type]" id="question_question_type" onChange={this.handleQuestionTypeChange.bind(this)} value={question_type}>
              <option value="multiple_choices">Multiple choices</option>
              <option value="essay">Essay</option>
              <option value="files">Files</option>
            </select>
            <span className="select">{ question_type }</span><i className="fa fa-angle-down"></i>
          </div>
        </div>

        { question_type === 'multiple_choices' && (
          <div>
            { options.map(({title, correct}, index) => {
              return (
                <div className="form-group" key={index}>
                  <label className="col-sm-1 control-label">{ OPTIONS[index] }</label>
                  <div className="col-sm-9">
                    <input type="text" name="question[options][][title]" id="question_options__title" onChange={(e) => this.onQuestionTitleChange(e, index)} value={title} placeholder={`Answer ${index}`} className="form-control" />
                  </div>
                  <div className="col-sm-2">
                    <div className="form-question mc-select">
                      <div className="form-question mc-select">
                        <select name="question[options][][correct]" id="question_options__correct" onChange={(e) => this.onQuestionCorrectChange(e, index)} value={correct}>
                          <option value="true">correct</option>
                          <option value="false">incorrect</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
        )}
        { question_type === 'files' && (
          <div>
            <div className="form-group" key="question-file-upload" style={{marginTop: "30px"}}>
                <input multiple="multiple" type="file" name="question[files][]" style={{color: "white"}} onChange={(e) => this.onQuestionFileAdd(e)}/><br/>
                 { this.props.question_files && (
                   this.props.question_files.map((file)=>{
                     return (
                     <div key={file.id} style={{color: "white"}}>
                        <span>{file.name}</span><a href={file.view} target="_blank"> View</a> <a href={file.delete} data-confirm="Confirm your action, please." data-method="delete">Delete</a><br/>
                     </div>)
                   })
                 )} 
              </div>
          </div>
        )}

        <div className="form-submit-1">
            <input type="submit" onClick={id ? this.updateQuestion : this.createQuestion} name="commit" value={ id ? 'Update' : 'Create' } className="mc-btn btn-style-1" data-disable-with="Create" />
            <button type="button" className="btn btn-block btn-secondary" data-dismiss="modal">Close</button>
        </div>

      </div>
    )
  }
}
