import React from 'react'
import axios from 'axios'

export default class WistiaPlayer extends React.Component {

  componentDidMount(){
    const { wistia_video_id, course_id, lesson_id } = this.props
    const learing = course_id && lesson_id

    window._wq = window._wq || [];
    _wq.push({ id: wistia_video_id, onReady: (video) => {
      if(learing) video.bind("end", async (t) => { await this.lessonCompleted(course_id, lesson_id) });
    } });
  }

  lessonCompleted = async (course_id, lesson_id) => {
    await axios({
      method: 'PUT',
      url: `/courses/${course_id}/lessons/${lesson_id}/completed.json`,
      data: { completed: true }
    }).then((response) => {
      //Turbolinks.visit(location.toString())
    }).catch((error) => {
      console.log('ERROR', error)
    })
  }

  render(){
    const { wistia_video_id } = this.props

    return (
      <div className={`wistia_embed wistia_async_${wistia_video_id}`} style={{ width: '100%', height: '460px' }} ></div>
    )
  }
}
