import React from 'react'

export default class Demo extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      name: props.name || 'Video Courses'
    }
  }

  componentDidMount(){
    console.log('Component Mounted')
  }

  render() {
    const { name } = this.state

    return (
      <div>
        <h1>Hi, { name }, I'm React component!</h1>
      </div>
    )
  }
}
