import React from 'react'

export default class Checkout extends React.Component {
  constructor(props){
    super(props)
  }

  componentDidMount(){

    const endpoint = '/user/subscriptions/paypal_subscription_completed.json'
    const csrfToken = document.querySelector('[name=csrf-token]').content

    paypal.Buttons({
      style: {
        label: 'pay',
        tagline: false,
        height: 48
      },
      createSubscription: (data, actions) => {
        // Paypal api don't have refference ID or walue that cound be used to pass user_id
        // There is not time for reasearch so this is "solution"
        return actions.subscription.create({
          plan_id: this.props.paypal_plain_id,
          application_context: {
            payment_method: { payee_preferred: "IMMEDIATE_PAYMENT_REQUIRED" }
          }
        })
      },
      onApprove: async (data, actions) => {
        // Idealy this should be done in webhook
        await fetch(endpoint, {
          method: "POST",
          body: JSON.stringify({ order_id: data.orderID, subscription_id: data.subscriptionID }),
          headers: { 'Content-Type': 'application/json', 'X-CSRF-TOKEN': csrfToken }
        })
        location.replace(location.toString())
      },
      onCancel: function (data) {
      },
      onError: function (err) {
        console.log(err)
      }


    }).render('#paypal-sub-button-container');

  }
  render() {

    return (
      <div id="paypal-sub-button-container"></div>
    )
  }
}
