import React from 'react'
import { WithContext as ReactTags } from 'react-tag-input'

const KeyCodes = {
  tab: 9,
  comma: 188,
  enter: 13,
}

const delimiters = [KeyCodes.comma, KeyCodes.enter, KeyCodes.tab]

export default class TagsInput extends React.Component {
  constructor(props) {
    super(props)
    const { tags } = this.props

    const mapTags = tags.map(tag => {
      const t = {}
      t.id = tag
      t.text = tag

      return t
    })

    this.state = {
      tags: mapTags
    }
  }

  handleDelete(i) {
    const { tags } = this.state;
    this.setState({
     tags: tags.filter((tag, index) => index !== i),
    });
  }

  handleAddition(tag) {
      this.setState(state => ({ tags: [...state.tags, tag] }));
  }

  render() {
    const { tags } = this.state

    return (
      <div className="">
        <ReactTags
          tags={tags}
          handleDelete={this.handleDelete.bind(this)}
          handleAddition={this.handleAddition.bind(this)}
          delimiters={delimiters}
          allowDragDrop={false}
          autofocus={false}
        />
        {
          tags.map(t => {
            return (<input key={t.id} name="course[tags][]" value={t.text} type="hidden" />)
          })
        }

      </div>
    )
  }
}
