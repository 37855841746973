import React from 'react'
import axios  from 'axios'

export default class WistiaUploader extends React.Component {

  updateCourse = (course_id, media) => {
    const { id: wistia_media_id, name: wistia_name, duration: wistia_duration, originalUrl: wistia_original_url } = media
    ///admin/courses/:id/wista_upload
    const course = {
      wistia_media_id,
      wistia_name,
      wistia_duration,
      wistia_original_url
    }

    axios({
      method: 'PUT',
      url: `/admin/courses/${course_id}/wista_upload.json`,
      data: course
    })
    .then((response) => {
      //TODO: form get clean up when video is uploaded
      //Turbolinks.visit(location.toString())
    })
    .catch((error) => {
      console.log('ERROR', error)
    })
  }

  updateLesson = (course_id, section_id, lesson_id, media) => {
    const { id: wistia_media_id, name: wistia_name, duration: wistia_duration, originalUrl: wistia_original_url } = media

    const lesson = {
      wistia_media_id,
      wistia_name,
      wistia_duration,
      wistia_original_url
    }

    axios({
      method: 'PUT',
      url: `/admin/courses/${course_id}/sections/${section_id}/lessons/${lesson_id}.json`,
      data: lesson
    })
    .then((response) => {
      Turbolinks.visit(location.toString())
    })
    .catch((error) => {
      console.log('ERROR', error)
    })
  }


  uploaded(file, media, embedCode, oembedResponse){
    const { course_id, section_id, lesson_id } = this.props

    const { id: wistia_media_id, name: wistia_name, duration: wistia_duration, originalUrl: wistia_original_url } = media

    if (lesson_id) {
      this.updateLesson(course_id, section_id, lesson_id, media)
    } else {
      this.updateCourse(course_id, media)
    }
  }



  componentDidMount(){
    const { wistia_project_id } = this.props

    window._wapiq = window._wapiq || [];
    _wapiq.push((W) => {
      window.wistiaUploader = new W.Uploader({
        accessToken: "05dcc20f604d0f4fbc9584720a6cf4f40b962f939e09a8bcfb3a2e4263a19bdd",
        dropIn: "wistia_uploader",
        preview: "wistia_upload_preview",
        projectId: wistia_project_id
      })

      wistiaUploader.bind("uploadembeddable", this.uploaded.bind(this))
    })

  }

  render() {
    return (
      <div id="wistia_uploader" style={{height: '360px', width: '100%'}}>
        <div id="wistia_upload_preview" style={{height: '360px', width: '100%'}}></div>
      </div>
    )
  }
}
